<template>
  <div>
    <v-form>
      <p class="mb-3">
        Consider the hypothetical kinetic data for the esterification reaction of
        2-phenylcyclohexanol with propionic anhydride using either
        <stemble-latex content="$(R)\text{-}$" />
        or
        <stemble-latex content="$(S)\text{-HBTM}$" />
        as a catalyst. Assume the rate constant for the reactions were approximately
        <stemble-latex
          content="$6.4\times10^{-4}\,\text{s}^{-1} \text{ ((}R\text{)-HBTM catalyst)}$"
        />
        and
        <stemble-latex
          content="$4.54\times10^{-5}\,\text{s}^{-1} \text{ (}(S\text{)-HBTM catalyst).}$"
        />
      </p>

      <p class="mb-3">
        Use the graph and the information above to determine which of the statements below is/are
        true. Select all correct statements.
      </p>

      <p class="mb-4 pl-6">
        <v-img src="/img/assignments/kineticsOfHBTMconversion.png" style="width: 444px" />
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="my-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_51LPL6_Q3',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {
          text: 'A shallower slope indicates a faster reaction.',
          value: 'shallower',
        },
        {
          text: 'A steeper slope indicates a faster reaction.',
          value: 'steeper',
        },
        {
          text: 'The R-HBTM catalyzed reaction is faster.',
          value: 'rHBTM',
        },
        {
          text: 'The S-HBTM catalyzed reaction is faster.',
          value: 'sHBTM',
        },
        {
          text: 'A larger rate constant leads to a faster reaction.',
          value: 'largerk',
        },
        {
          text: 'A smaller rate constant leads to a faster reaction.',
          value: 'smallerk',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
